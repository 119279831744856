@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;600;700&display=swap');

/* Apply Lexend Deca to the whole dashboard */
body, .dashboard-container {
  font-family: 'Helvetica', sans-serif;
}

/* Ensure headers use it */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Helvetica', sans-serif;
}

/* Apply to buttons and text elements */
button, p, span {
  font-family: 'Helvetica', sans-serif;
}

.dashboard-container .lead {
  color: black !important;
}

.dashboard-container .content-box p {
  color: black !important;
}

.dashboard-container {
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #fff;
  overflow: hidden;
}

/* Welcome Section - Video Background */
.dashboard-container .welcome-section {
  position: relative;
  width: 100vw;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.dashboard-container .bg-video {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.dashboard-container .welcome-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

/* Dark Overlay for Better Text Visibility */
.dashboard-container .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adds a slight dark overlay */
  z-index: -1; /* Keeps overlay above video but behind text */
}


.dashboard-container .welcome-overlay h3{
  margin-top: 10%;
  font-weight: 500;
}
/* Responsive Text */
.dashboard-container .welcome-overlay h1 {
  font-size: 2.1rem;
  font-weight: bold;
  /* margin-left: 10%;
  margin-right: 15%; */
  /* margin-top: 10%; */
}

.dashboard-container .welcome-overlay p {
  font-size: 1.4rem;
  margin-top: 10px;
  word-spacing: 2px;
}

/* Optional: Add a slight text shadow for readability */
.dashboard-container .welcome-overlay h1,
.dashboard-container .welcome-overlay p {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.dashboard-container .welcome-overlay .stellar-color{
  color: #DAAA00;
}

.dashboard-container .welcome-overlay .btn{
  background-color: #DAAA00;
  color: #000000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
} 

/* Features Section */
.features-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 40px 10%;
  width: 100%;
  text-align: left;
}

/* Headings */
 .feature-heading {
  font-size: 2rem;
  font-weight: bold;
  margin: 1% 1%;
  text-align: center;
}

 .feature-subheading {
  font-size: 17px;
  color: #202020;
  padding-bottom: 50px;
  text-align: center;
  margin: 0% 10%;
}

/* Layout - Feature List (Left) and Image (Right) */
 .features-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1500px;
  width: 100%;
}

/* Left Side - Feature List */
 .features-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 40px;
  /* margin-right: 10px; */
}

.feature-item {
  padding: 15px 10px;
  border-left: 3px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #444;
}

.feature-item:hover,
.feature-item.active {
  border-left: 3px solid #DAAA00;
  background: rgba(238, 202, 44, 0.1);
  color: #222;
}

.feature-item h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.feature-item p {
  font-size: 1rem;
  color: #444;
  line-height: 1.4;
}

/* Right Side - Image Display */
.feature-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-image img {
  width: 1000px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
}
.features-section .stellar-color{
  color: #DAAA00;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .features-layout {
    flex-direction: column;
    align-items: center;
  }

  .features-list {
    padding-right: 0;
    width: 100%;
    text-align: center;
  }

  .feature-item {
    border-left: none;
    border-bottom: 2px solid transparent;
  }

  .feature-item:hover,
  .feature-item.active {
    border-bottom: 3px solid #DAAA00;
    background: rgba(0, 123, 255, 0.1);
  }

  .feature-image {
    margin-top: 20px;
  }

  .feature-image img {
    max-width: 650px;
  }
}

.faq-sect{
  background: rgba(238, 202, 44, 0.1);
width: 100%;
}

/* FAQ Section */
.faq-section {
  width: 70%;
  /* margin: 50px auto; */
  /* background: #F6F9FC; */
  /* padding: 40px;
  border-radius: 12px; */
  text-align: center;
  padding-bottom: 20px;
}

/* FAQ Title */
.faq-head {
  font-size: 2rem;
  font-weight: 700;
  color: #222;
  margin-top: 5%;
}


.faq-section .faq-subtext{
  color: black;
  margin-bottom: 50px;
  top:0%;
  font-size: 17px;
  padding: 0% 5%;
}
/* Accordion Styling (Scoped to FAQ) */
.faq-section .accordion-item {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

/* Scoped Accordion Button */
.faq-section .accordion-button {
  font-size: 16px;
  font-weight: 600;
  background: #fff !important; /* Ensure white background */
  color: #000 !important; /* Black text */
  padding: 25px;
  border-radius: 8px;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* Remove Bootstrap's default blue overlay on active state */
.faq-section .accordion-button:not(.collapsed) {
  background: #fff !important; /* Keep white */
  color: #000 !important; /* Keep black text */
  box-shadow: none !important;
}

/* Remove focus outline */
.faq-section .accordion-button:focus {
  box-shadow: none !important;
  outline: none !important;
}

/* Smooth transition */
.faq-section .accordion-body {
  transition: all 0.3s ease-in-out;
  background: #fff !important;
  color: #333 !important;
}

.faq-section .accordion-body p {
  color: black;
  text-align: left;
}

.faq-section .accordion-body li {
  text-align: left;
}
/* Hover Effect */
.faq-section .accordion-button:hover {
  color: #DAAA00 !important;
  /* background: #FDFAE9 !important; */
}

.stellar-color{
  color: #DAAA00;
}

/* Responsive Design */
@media (max-width: 768px) {

  .dashboard-container .welcome-overlay h1 {
    font-size: 2.2rem;
    font-weight: bold;
    padding: 0 0px;
  }
  
  .dashboard-container .welcome-overlay p {
    display: none;
  }


  .feature-heading {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 5% 5%;
    text-align: center;
  }
  .faq-section {
    width: 100%;
    padding: 20px;
  }
  
  .faq-section .accordion-item {
    width: 100%;
  }
}

/* Footer Styling */
.contact-section {
  width: 100%;
  background: #000;
  padding: 20px 0;
  text-align: center;

}
.contact-section p{
  color: #DAAA00;
}

/* Initial state for sections */
.animate-section {
  opacity: 0; /* Start hidden */
  transform: translateY(50px); /* Start 50px below */
  transition: opacity 0.8s ease-out, transform 0.8s ease-out; /* Smooth transition */
}

/* Animated state when section is visible */
.animate-section.is-visible {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Move to original position */
}

/* Responsive adjustments for large screens */
@media (min-width: 1024px) {
  .features-layout {
    padding: 0 5%;
    gap: 40px;
  }

  .features-list {
    padding-right: 30px;
  }

  .feature-image img {
    width: 100%;
    max-width: 700px;
    height: auto;
  }
}

@media (min-width: 1440px) {
  .features-layout {
    gap: 60px;
  }

  .feature-image img {
    max-width: 600px;
  }

  .features-list {
    padding-right: 60px;
  }

  .feature-item h3 {
    font-size: 1.4rem;
  }

  .feature-item p {
    font-size: 1.05rem;
    line-height: 1.6;
  }
}
