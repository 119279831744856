/* Container Styling */
.tickets-page-container {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto 20px auto;
  min-height: 70vh;
}

/* Main Title */
.tickets-page-title {
  font-size: 2.2rem;
  font-weight: 700;
  color: #007bff;
  margin-bottom: 30px;
  text-align: left;
}

/* Section Styling */
.tickets-section {
  margin-bottom: 40px;
}

.tickets-section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #343a40;
  margin-bottom: 20px;
  text-align: left;
  position: relative;
  padding-bottom: 8px;
}

.tickets-section-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background-color: #007bff;
  border-radius: 2px;
}

/* Table Styling */
.tickets-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.tickets-table th,
.tickets-table td {
  padding: 12px 15px;
  text-align: left;
  font-size: 1rem;
  color: #343a40;
}

.tickets-table th {
  background-color: #f1f3f5;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #000000;
  border-bottom: 2px solid #dee2e6;
}

.tickets-table td {
  border-bottom: 1px solid #dee2e6;
}

/* Table Row Styling */
.tickets-table-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.tickets-table-row:hover {
  background-color: #f8f9fa;
}

/* Status Badge Styling */
.tickets-status-badge {
  padding: 6px 14px;
  border-radius: 20px;
  font-weight: 600;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 0.85rem;
  white-space: nowrap;
  display: inline-block;
}

/* Status Colors */
.tickets-status-badge.status-new {
  background-color: #007bff;
}

.tickets-status-badge.status-in-progress {
  background-color: #ffc107;
  color: #212529;
}

.tickets-status-badge.status-resolved {
  background-color: #28a745;
}

.tickets-status-badge.status-cancelled {
  background-color: #dc3545;
}

.tickets-status-badge.status-reopened {
  background-color: #17a2b8;
}

.tickets-status-badge.status-closed {
  background-color: #6c757d;
}

/* Loading and Error States */
.tickets-loading {
  font-size: 1.1rem;
  color: #6c757d;
  text-align: center;
  padding: 20px;
}

.tickets-error {
  font-size: 1.1rem;
  color: #dc3545;
  text-align: center;
  padding: 20px;
  background-color: #f8d7da;
  border-radius: 8px;
}

/* No Tickets Message */
.tickets-empty {
  font-size: 1rem;
  color: #6c757d;
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .tickets-page-container {
    padding: 20px;
    margin: 10px;
  }

  .tickets-page-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .tickets-section-title {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }

  .tickets-table th,
  .tickets-table td {
    padding: 10px;
    font-size: 0.85rem;
  }

  .tickets-status-badge {
    padding: 5px 10px;
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .tickets-page-container {
    padding: 15px;
  }

  .tickets-page-title {
    font-size: 1.5rem;
  }

  .tickets-section-title {
    font-size: 1.2rem;
  }

  /* Make table scrollable on small screens */
  .tickets-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .tickets-table th,
  .tickets-table td {
    min-width: 120px; /* Ensure columns don't collapse too much */
  }
}