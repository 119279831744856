.form-control {
    min-width: 100px;
  }
  
  .btn {
    padding: 4px 8px;
  }
  
  .btn-sm i {
    font-size: 0.85rem;
  }
  