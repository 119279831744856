.status-form {
    margin: 20px;
    padding: 20px;
    background-color: #ffffff; 
    border: 1px solid #e6e6e6; 
    border-radius: 10px; 
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
    font-family: 'Arial', sans-serif;
  }
  
  .status-form .form-group {
    margin-bottom: 20px;
  }
  
  .status-form .form-label {
    font-weight: 600;
    color: #333333; 
    display: inline-block;
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .status-form select.form-control {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 14px;
    line-height: 1.5;
    color: #495057; 
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 6px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); 
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .status-form select.form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
  
  .status-form textarea.form-control {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    resize: none;
  }
  
  .status-form textarea.form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
  
  .status-form .btn {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .status-form .btn:hover {
    background-color: #0056b3; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }
  
  .status-form .btn:active {
    background-color: #004085; 
    box-shadow: none;
  }
  
  @media (max-width: 768px) {
    .status-form {
      padding: 15px;
    }
  
    .status-form .btn {
      font-size: 14px;
      padding: 10px;
    }
  }
  
  .status-form select.form-control:disabled,
  .status-form textarea.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
  
  .status-form .form-label.required::after {
    content: " *";
    color: #dc3545;
    margin-left: 4px;
  }
  