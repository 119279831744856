/* body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
} */

.login-page {
  position: relative;
  height: 100vh;
  animation: slide 15s infinite alternate;
  background-size: cover;
  background-position: center;
  flex-direction: column;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.login-container {
  position: relative;
  z-index: 2;
  height: calc(100vh - 43px); /* Adjust height to account for the banner */
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box {
  background: black;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 90%;
  color: white;
}

.logo-img {
  width: 200px;
  height: auto;
}

.login-box .termscheckbox{
  width: 25px;
  height:15px;
  vertical-align:auto;
  margin-right: 0px;
}
.login-box .termsbox{
  display: inline;
  font-size: 0.9rem;
  padding-left: 0%;
}
.login-button{
  margin-top: 0%;
  height: 50px;
}


.otp-inputs {
  gap: 10px;
}

.otp-box {
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  text-align: center;
  border: 2px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.otp-box:focus {
  border-color: #4e54c8;
  outline: none;
}

/* Footer */
.footer {
  position: relative;
  bottom: unset;
  bottom: 0;
  width: 100%;
  background: #333;
  padding: 1rem 0;
  margin-top: auto;
  color: white;
  height: 15%;
}

/* Background image slider */
@keyframes slide {
  0% { background-image: url('./../assets/0_sky-08.jpg'); }
  50% { background-image: url('./../assets/0_sky-08.jpg'); }
  100% { background-image: url('./../assets/0_sky-08.jpg'); }
}



/* Modal Styles */
.login-modal .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-modal .modal-content {
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  position: relative;
  color: black;
  max-height: 80vh;
  overflow: hidden;
}

.login-modal .modal-content h3{
  text-align: center;
}

.login-modal .modal-close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 2rem;
  color: #333; /* Changed from white to dark gray for visibility */
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1001;
  font-weight: bold;
  transition: transform 0.2s ease, color 0.2s ease;
}

.login-modal .modal-close-btn:hover {
  color: #ff4d4d; /* Bright red on hover */
  transform: scale(1.2);
}



.login-modal .modal-document {
  max-height: 60vh;
  overflow-y: auto;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* background: #f9f9f9; */
}

.login-modal .modal-document pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}

 .banner {
  position: relative;
  z-index: 3; /* Ensure it is above the overlay */
  background-color: transparent;
  padding: 10px;
  /* border-bottom: 1px solid #ddd; */
  text-align: center;
}

 .banner p {
  margin: 0;
  font-size: 14px;
}

 .banner .btn-link {
  color: #e9c13d;
  text-decoration: none;
  font-weight: bold;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
}

 .banner .btn-link:hover {
  text-decoration: underline;
  color: #ffc400;
}