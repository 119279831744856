/* ForgetPassword.css - Making the page super stylish */
p{
    color:#ffffff
}
.forget {
    background: url('/src/assets/0_sky-08.jpg') no-repeat center center fixed;
    background-size: cover;
    position: relative; /* Make sure the parent container is positioned relative */
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    overflow: hidden; /* Prevent scrollbars caused by animations or offsets */
}

.forget::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black transparent overlay */
    z-index: 0; /* Ensure it sits below the container content */
}

.forget .container {
    position: relative; /* Ensure content is above the overlay */
    z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.forget .card {
    background: #000000;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.8s ease-in-out;
    color: #fff;
}


@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

.forget h3 {
    color: #ffffff;
    font-weight: 700;
}

.forget .text-muted {
    font-size: 0.8rem;
    color: #ffffff !important;
}

.forget .form-label {
    color: #ffffff;
    font-weight: 600;
}

.forget .form-control {
    border-radius: 5px;
    padding: 12px;
    border: 2px solid #ffffff;
}

.forget .form-control:focus {
    border-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(43, 82, 180, 0.5);
}

.forget .btn-primary {
    /* background: linear-gradient(90deg, #2f50d4, #ffffff); */
    border: none;
    padding: 12px;
    font-size: 1rem;
    border-radius: 5px;
    transition: all 0.3s;
}

.forget .btn-primary:hover {
    /* background: linear-gradient(90deg, #d500f9, #1a237e); */
    transform: scale(1.05);
}

.forget .alert {
    border-radius: 10px;
    font-weight: 600;
}

.forget .bi {
    color: #ffffff;
    font-size: 1.2rem;
}


.forget footer {
    position: absolute;
    bottom: 10px;
    font-size: 0.8rem;
    color: #fff;
    width: 100%;
    text-align: center;
}



@media (max-width: 576px) {
    .forget .card {
        padding: 20px;
    }
    .forget .btn-primary {
        font-size: 0.9rem;
    }
    .forget .form-control {
        padding: 10px;
    }
}
