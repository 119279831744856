.home .section-title {
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem; 
  margin-bottom: 25px; 
  color: #333;
  text-align: center; 
}

.home .card {
  border: none;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
  background: #ffffff; 
  height: 100%; 
}

.home .card:hover {
  transform: translateY(-8px); 
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15); 
}

.home .card-title {
  font-family: "Poppins", sans-serif; 
  font-weight: 600;
  font-size: 1.2rem; 
  color: #0056b3; 
  margin-bottom: 15px; 
}

.home .card-text {
  font-size: 0.95rem; 
  color: #6c757d; 
}

.home .text-primary {
  text-transform: capitalize; 
  font-weight: 700; 
}

.home .btn {
  font-family: "Poppins", sans-serif; 
  font-size: 1rem; 
  font-weight: 600; 
  padding: 10px 15px; 
  border-radius: 5px; 
  transition: all 0.3s ease-in-out; 
}

.home .btn-primary {
  background: linear-gradient(45deg, #007bff, #0056b3); 
  color: #fff;
  border: none;
}

.home .btn-primary:hover {
  background: linear-gradient(45deg, #0056b3, #004085); 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
  transform: translateY(-2px);
}

.home .icon-container {
  width: 70px; 
  height: 70px;
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem; 
  margin: 0 auto 15px auto; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
  color: #fff; 
}

.home .icon-container.bg-primary {
  background-color: #007bff; 
}

.home .icon-container.bg-success {
  background-color: #28a745; 
}

.home .icon-container.bg-warning {
  background-color: #ffc107;
}

@media (max-width: 768px) {
  .home .section-title {
    font-size: 1.5rem; 
  }

  .home .card-title {
    font-size: 1.1rem; 
  }

  .home .card-text {
    font-size: 0.85rem; 
  }

  .home .icon-container {
    width: 60px; 
    height: 60px;
    font-size: 1.5rem;
  }
}
