/* People.css - Clean, Professional & Left-Aligned */

.people .people-container {
  margin-top: 20px;
  padding: 0 20px;
}

.people .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.people .section {
  margin-bottom: 30px;
  text-align: left; /* Ensures all content is aligned left */
}

.people .section-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1a237e;
  margin-bottom: 15px;
}

.people .people-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start; /* Aligns items to the left */
  align-items: flex-start; /* Ensures top alignment */
}

@media (max-width: 768px) {
  .people .people-list {
      flex-direction: column;
      align-items: flex-start; /* Ensures left alignment even on small screens */
  }
}
