/* Logo Styling */
.navbar-logo {
  height: 60px;
  width: auto;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
}

/* Navbar Brand */
.navbar-brand {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  color: #ffffff !important;
  text-transform: uppercase;
}

.navbar-brand:hover {
  color: #e0f7fa !important;
  text-decoration: none;
}

/* Background and Navbar Colors */
.bg-black {
  background: #000000; /* Black background */
  border-bottom: 2px solid #ffd700; /* Yellow bottom border */
}

/* Navbar Links */
.nav-link {
  color: #ffffff !important;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  padding: 10px 15px;
}

.nav-link:hover {
  background-color: #00574b;
  border-radius: 5px;
  color: #e0f7fa !important;
}

/* Active Link */
.navbar .nav-link.active {
  /* background-color: #004d40; */
  color: #e0f2f1 !important;
  border-radius: 5px;
  color:#DAAA00 !important;
}

/* Dropdown Menu */
.dropdown-menu {
  background-color: #ffffff;
  color: #004d40;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  color: #004d40 !important;
  font-size: 0.9rem;
  padding: 8px 16px;
}

.dropdown-item:hover {
  background-color: #e0f2f1 !important;
  color: #4d4100 !important;
  border-radius: 5px;
}

/* Profile Dropdown */
.profile-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  z-index: 1050;
  min-width: 180px;
}

.profile-li {
  position: relative !important;
}

.profile-ul {
  position: absolute;
  right: 0 !important;
  left: auto !important;
  z-index: 1050 !important;
  padding: 0.5rem 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Logout Link */
.nav-link.text-danger {
  font-weight: bold;
  color: #ff5252 !important;
}

.nav-link.text-danger:hover {
  color: #ff7961 !important;
}

/* Icons */
.icon {
  color: #ffffff;
  font-size: 1.2rem;
  width: 28px;
  height: 28px;
  transition: color 0.3s ease-in-out;
}

.icon:hover {
  color: #e0f7fa;
}

/* Button Styling */
.btn-outline-light {
  color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-light:hover {
  background-color: #ffffff;
  color: #DAAA00;
  border-color: #ffffff;
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar-brand {
    font-size: 1.2rem;
  }

  .nav-link {
    font-size: 0.9rem;
    padding: 8px 10px;
  }

  .dropdown-menu {
    font-size: 0.9rem;
  }
}
