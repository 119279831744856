/* Prefix: .profile-container */

/* General Container */
.profile-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    font-family: "Arial", sans-serif;
  }
  
  /* Profile Card */
  .profile-card {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 30px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .profile-image img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid #007bff;
  }
  
  .profile-info {
    margin-left: 20px;
  }
  
  .profile-info h2 {
    font-size: 22px;
    font-weight: 600;
    color: #333;
  }
  
  .profile-role {
    font-size: 16px;
    color: #666;
    margin-top: 5px;
  }
  
  /* Change Password Form */
  .change-password-container {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .change-password-container h3 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }
  
  .change-password-form .form-group {
    margin-bottom: 15px;
  }
  
  .change-password-form label {
    font-size: 14px;
    font-weight: 600;
    color: #555;
  }
  
  .change-password-form .form-control {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .change-password-form .form-control:focus {
    border-color: #007bff;
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.2);
  }
  
  .error-message {
    color: #dc3545;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 15px;
  }
  
  .success-message {
    color: #28a745;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 15px;
  }
  
  .change-password-form .btn {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
  }
  
  /* Modal Styles */
  .modal-body .form-group {
    margin-bottom: 15px;
  }
  
  .modal-body label {
    font-size: 14px;
    font-weight: 600;
    color: #555;
  }
  
  .modal-body .form-control {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .modal-footer .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .modal-footer .btn-primary:hover {
    background-color: #0056b3;
  }
  