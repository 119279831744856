/* AdminDashboard.css - Scoped styling for this dashboard only */

.admin-dashboard-container {
    padding: 30px;
    background-color: #f4f6f9;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    font-family: "Segoe UI", sans-serif;
  }
  
  .admin-dashboard-title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #2c3e50;
  }
  
  .admin-dashboard-filter {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 40px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.07);
  }
  
  .admin-dashboard-filter label {
    font-weight: 500;
    color: #444;
    margin-bottom: 6px;
  }
  
  .admin-dashboard-filter .form-control {
    border-radius: 6px;
    font-size: 14px;
  }
  
  .admin-dashboard-btn {
    margin-top: 8px;
    background-color: #3498db;
    border-color: #3498db;
  }
  
  .admin-dashboard-btn:hover {
    background-color: #2980b9;
    border-color: #2980b9;
  }
  
  .admin-dashboard-chart {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    margin-bottom: 40px;
  }
  
  .admin-dashboard-chart h5 {
    font-size: 18px;
    font-weight: 600;
    color: #34495e;
    margin-bottom: 20px;
  }
  