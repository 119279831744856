.ticket-view-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    color: black;
  }
  
  .ticket-details {
    font-size: 1rem;
    color: black !important;
  }
  
  .status-buttons {
    display: flex;
    justify-content: flex-end; /* Aligns buttons to the right */
    width: 100%; /* Ensures it takes the full width */
    padding-right: 10px; /* Adds spacing from the right edge */
    gap: 5px; /* Adds even spacing between buttons (modern browsers) */
  }


  .status-buttons > * {
      margin-left: 5px; /* Adds spacing between buttons */
  }

 
  .status-buttons > *:first-child {
      margin-left: 0;
  }
  
  .messages-container {
    margin-top: 20px;
  }
  
  .message-list {
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 10px;
    background-color: #f8f9fa;
  }
  
  /* .message-item {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
  } */
  
  .message-item:last-child {
    border-bottom: none;
  }
  
  /* .message-meta {
    font-size: 0.9rem;
    margin-bottom: 5px;
  } */
  
  .message-content {
    font-size: 1rem;
    color: black !important;
  }
  
  .reply-section {
    margin-top: 20px;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  .loading-spinner img {
    padding-top: 30%;
    width: 50px;  
    height: auto;
  }
  
  .sent {
    background-color: #d1e7dd; 
    text-align: right;
    padding: 10px;
    border-radius: 10px;
    margin: 5px 0;
    align-self: flex-end;
  }
  
  .received {
    background-color: #f0ecec; /* Light red for received messages */
    text-align: left;
    padding: 10px;
    border-radius: 10px;
    margin: 5px 0;
    align-self: flex-start;
  }
  
  .message-item {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  
  .message-meta {
    font-size: 0.9rem;
    color: gray;
  }
  .modal-title{
   
    color: white !important;
  }

  .modal-header{
    background-color: #198754;
  }