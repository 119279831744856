/* AddPerson.css */

.add-person-container {
  max-width: 700px;
  margin: auto;
}

.add-person-container .form-label {
  font-weight: 500;
}

.add-person-container .form-control {
  border-radius: 5px;
  padding: 10px;
}

/* Aligned switch label and input */
.add-person-container .toggle-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.add-person-container .toggle-row label {
  margin-bottom: 0;
  font-weight: 500;
}

.add-person-container .form-check-input {
  transform: scale(1.2);
}

/* Eye icon styling */
.position-relative {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 70%; /* Adjust based on your form-control height */
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
}

/* Centered Submit Button */
.submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

/* Responsive spacing */
@media (max-width: 576px) {
  .add-person-container {
    padding: 0 1rem;
  }

  .submit-button-container {
    margin-top: 1.5rem;
  }

  .eye-icon {
    top: 65%; /* Adjust for smaller screens if needed */
  }
}