.dropdown-menu {
    max-width: 400px;
}
.scrollable-container {
    max-height: 120px;
    overflow-y: scroll;
  }
  
  .scrollable-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .scrollable-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
  
  .scrollable-container::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }
  

  .filters .dropdown-toggle{
    font-weight: 400;
  }