/* Prefix: .applications-container */

/* Container Styling */
.applications-container {
  padding: 30px 70px;
  background: rgb(244, 248, 251);
  /* background: linear-gradient(to bottom right, #eef2ff, #fdf2f8); */
  /* background: linear-gradient(to bottom right,LightSkyBlue, AliceBlue); */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", serif;
  font-weight: 300;
  font-style: normal;
  min-height: 100%;

}

.applications-container h1.page-title {
  font-size: 30px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 8px;

}

.applications-container .btn-success {
  background: linear-gradient(45deg, #28a745, #218838);
  color: #fff;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  padding: 8px 24px;
  
}

.applications-container .btn-success:hover {
  background: linear-gradient(45deg, #218838, #1e7e34);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.applications-container .btn-primary {
  /* background: indigo; */
  font-weight: 400;
  color: #fff;
  transition: all 0.3s ease-in-out;
  padding: 10px 24px;
  border: 0px !important;
}

.applications-container .btn-primary:hover {
  background: #3b4cc0 ;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}



/* Filters Section */
.applications-container .input-group {
  
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  border: 1px solid rgb(226 232 240 / var(1, 1));
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  
}

.applications-container .input-group input {

  border: none;
  padding: 10px 15px;
  font-size: 16px;
}

.applications-container .input-group  .btn {
  border: none;
  font-weight: 400;
}

/* Table Styling */

.applications-container .table-above {
  border: 1px solid rgb(229, 231, 235);
  border-radius: 10px !important;
  overflow-x: auto;

}

.applications-container table {
  width: 100%; 
  background: #fff;
  border-radius: 10px !important;
  border-collapse: collapse;
  
  
}

.applications-container table thead {
  background: rgb(244, 248, 251);
  color: black;
  border-radius: 10px !important;
}

.applications-container table thead .tr th:first-child {
  border-top-left-radius: 10px;
}

.applications-container table thead .tr th:last-child {
  border-top-right-radius: 10px;
}

.applications-container table thead th {
  padding: 16px 16px;
  font-size: 16px;
  text-align: left;
}

.applications-container table tbody tr {
  border-bottom: 1px solid rgb(229, 231, 235);
  transition: background-color 0.2s ease-in-out;
}

.applications-container table thead tr {
  border-bottom: 1px solid rgb(229, 231, 235);
  transition: background-color 0.2s ease-in-out;
}

.applications-container table tbody tr:hover {
  background: rgb(244, 248, 251);
}

.applications-container table td {
  padding: 12px 16px;
  font-size: 16px;
  color: #555;
  min-width: 130px !important;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .applications-container  {
    padding: 20px;
  }

  .applications-container .input-group {
    margin-bottom: 15px;
  }

  .applications-container table {
    display: block;
    overflow-x: auto;
  }
}

.range #debt-amount-slider {
	display: flex;
	flex-direction: row;
	align-content: stretch;
	position: relative;
	width: 100%;
	height: 40px; /* Reduced height */
	user-select: none;
}

.range #debt-amount-slider::before {
	content: " ";
	position: absolute;
	height: 2px;
	width: calc(100% * (4 / 5));
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #000;
}

.range #debt-amount-slider input,
.range #debt-amount-slider label {
	box-sizing: border-box;
	flex: 1;
	user-select: none;
	cursor: pointer;
}

.range #debt-amount-slider label {
	display: inline-block;
	position: relative;
	width: 20%;
	height: 100%;
	user-select: none;
}

.range #debt-amount-slider label::before {
	content: attr(data-debt-amount);
	position: absolute;
	left: 50%;
	padding-top: 8px;
	transform: translate(-50%, 30px);
	font-size: 12px; /* Smaller font */
	letter-spacing: 0.4px;
	font-weight: 400;
	white-space: nowrap;
	opacity: 0.85;
	transition: all 0.15s ease-in-out;
}

.range #debt-amount-slider label::after {
	content: " ";
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 20px; /* Smaller circle */
	height: 20px; /* Smaller circle */
	border: 2px solid #000;
	background: #fff;
	border-radius: 50%;
	pointer-events: none;
	user-select: none;
	z-index: 1;
	cursor: pointer;
	transition: all 0.15s ease-in-out;
}

.range #debt-amount-slider label:hover::after {
	transform: translate(-50%, -50%) scale(1.2);
}

.range #debt-amount-slider input {
	display: none;
}

.range #debt-amount-slider input:checked + label::before {
	font-weight: 800;
	opacity: 1;
}

.range #debt-amount-slider input:checked + label::after {
	border-width: 3px;
	transform: translate(-50%, -50%) scale(1.2);
}
