.admin-main {
    display: flex;
    height: 100vh;
  }
  
  .admin-main .sidebar {
    width: 250px;
    position: fixed;
    margin-top:0.5%;
    left: 0;
    height: 100%;
    background-color: #343a40;
    color: #ffffff;
    overflow-y: auto;
    transition: width 0.3s ease; /* Smooth transition for width */
  }
  
  .admin-main .sidebar-title {
    text-align: center;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #212529;
    white-space: nowrap;
  }
  
  .admin-main .nav-link,
  .admin-main .sub-nav-link {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: #ffffff;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-left: 4px solid transparent;
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .admin-main .nav-link i,
  .admin-main .sub-nav-link i {
    margin-right: 0.5rem;
  }
  
  .admin-main .nav-link:hover,
  .admin-main .sub-nav-link:hover {
    background-color: #495057;
  }
  
  .admin-main .nav-link.active,
  .admin-main .sub-nav-link.active {
    background-color: #495057;
    color: #00d1b2;
    font-weight: bold;
    border-left: 4px solid #00d1b2;
  }
  
  .admin-main .content {
    margin-left: 250px;
    padding: 1rem;
    flex-grow: 1;
    background-color: #f8f9fa;
    overflow-y: auto;
    transition: margin-left 0.3s ease; /* Smooth transition for margin */
  }
  
  /* Responsive Styles for Smaller Screens */
  @media (max-width: 768px) {
    .admin-main .sidebar {
      width: 80px; /* Shrink the sidebar width */
    }
  
    .admin-main .sidebar-title {
      font-size: 1rem;
      padding: 0.5rem;
    }
  
    .admin-main .nav-link,
    .admin-main .sub-nav-link {
      justify-content: center; /* Center the icon */
      padding: 0.75rem 0; /* Adjust padding */
    }
  
    .admin-main .nav-link i,
    .admin-main .sub-nav-link i {
      margin-right: 0; /* Remove the right margin for icons */
    }
  
    .admin-main .nav-link span,
    .admin-main .sub-nav-link span {
      display: none; /* Hide the text */
    }
  
    .admin-main .content {
      margin-left: 80px; /* Adjust content margin */
    }
  }
  

  .admin-main .loading {
    padding: 0.5rem 1rem;
    color: #adb5bd;
    font-style: italic;
  }
  
  .admin-main .no-domains {
    padding: 0.5rem 1rem;
    color: #ff6b6b;
    font-weight: bold;
  }
  

  