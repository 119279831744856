/* Prefix: .application-card */

/* Row Styling */
.application-card {
  font-family: "Poppins", serif;
  font-size: 16px;
  font-weight: 400;
  transition: background-color 0.2s ease-in-out;
  height: 90px;
}

.application-row td {
  vertical-align: middle; /* Ensure content is vertically centered */
  padding: 15px; /* Add padding for more spacing */
}
.application-row .btn-sm {
  
  padding: 6px 12px !important;
}

.application-row .text-center {
  width: 100px !important;
  font-weight: 600;
  text-transform: capitalize;
}

.application-card:hover {
  background-color: #f8f9fa;
}

/* Action Button */
.application-card .btn {
  font-size: 13px;
  font-weight: 600;
  padding: 6px 10px;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.application-card .btn-success {
  background: linear-gradient(45deg, #28a745, #218838);
  color: #fff;
  border: none;
  border-radius: 4px;
}

.application-card .btn-success:hover {
  background: linear-gradient(45deg, #218838, #1e7e34);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.application-card .text-muted {
  color: #6b7280;
}

/* Contact Section */
.application-card a {
  color: #0056b3;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.application-card a:hover {
  color: #003f88;
  text-decoration: underline;
}

.application-card td {
  padding: 50px;
}

/* Status Column */
.application-card .badge {
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 600;
  height: 30px;
  text-transform: uppercase;
  border-radius: 4px;
}

.application-card .badge.bg-warning {
  background: linear-gradient(45deg, #ffe082, #ffcc80); /* Lighter tones */
  color: #212529;
  border-radius: 20px;
}

.application-card .badge.bg-warning.text-dark {
  color: black;
}

.application-card .btn-warning {
  border: 1px solid #fd7e14; /* Match one gradient color */
  background: linear-gradient(45deg, #fd7e14, #e96b00); /* Slightly deeper tones */
  color: #fff;
  font-weight: bold;
}

.application-card .btn-warning:hover {
  background: linear-gradient(45deg, #e96b00, #d65a00); /* Darker hover effect */
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}


.application-card .email {
  color: indigo;
}

.application-card .email:hover {
  color: #3730a3;
}

.application-card .text-gray {
  color: #111827;
}

.application-card .table-button {
  border: 1px solid gray !important;
}

.modal-content {
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.modal-header {
  background-color: #343a40;
  color: #fff;
}

.modal-footer .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.modal-footer .btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}


/* Responsive Styling */
@media (max-width: 768px) {
  .application-card td {
    font-size: 12px;
  }

  .application-card .btn {
    font-size: 12px;
    padding: 4px 8px;
  }
}
