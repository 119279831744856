/* ResetPassword.css - Stylish and responsive design */

.reset {
    background: url('/src/assets/0_sky-08.jpg');
    background-size: cover;
    position: relative; /* Make sure the parent container is positioned relative */
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
  }

  .reset::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black transparent overlay */
    z-index: 0; /* Ensure it sits below the container content */
  }
  
  .reset .reset-container {
    text-align: center;
  }
  
  .reset .reset-card {
    background: #000;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.8s ease-in-out;
    max-width: 450px;
    width: 100%;
    color: #fff;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .reset h3 {
    color: #ffffff;
    font-weight: 700;
  }
  
  .reset .text-muted {
    font-size: 0.9rem;
  }
  
  .reset .form-label {
    color: #ffffff;
    font-weight: 600;
    text-align: left;
    display: block;
  }
  
  .reset .form-control {
    border-radius: 5px;
    padding: 12px;
    border: 2px solid #2c3e50;
  }
  
  .reset .btn-primary {
    /* background: linear-gradient(90deg, #2c3e50, #8e44ad); */
    border: none;
    padding: 12px;
    font-size: 1rem;
    border-radius: 5px;
    transition: all 0.3s;
  }
  
  .reset .btn-primary:hover {
    /* background: linear-gradient(90deg, #8e44ad, #2c3e50); */
    transform: scale(1.05);
  }
  
  .reset .alert {
    border-radius: 10px;
    font-weight: 600;
  }
  
  .reset .logo {
    width: 120px;
  }
  
  .reset footer {
    position: absolute;
    bottom: 10px;
    font-size: 0.8rem;
    color: #fff;
    width: 100%;
    text-align: center;
  }
  
  .reset .password-requirements {
    list-style: none;
    padding-left: 0;
    font-size: 0.9rem;
  }
  
  .reset .password-requirements li {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
  }
  
  .reset .valid {
    color: green;
  }
  
  .reset .invalid {
    color: red;
  }

  .reset .input-group {
    position: relative;
    width: 100%; /* Ensures the input and icon take up the full width of the container */
  }
  
  .reset .input-group input {
    width: 100%; /* Full width input field */
    padding-right: 40px; /* Leaves space for the eye icon */
  }
  
  .reset .password-toggle {
    position: absolute;
    right: 10px; /* Adjust this for padding between the icon and the input's right edge */
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;
    color: #555;
    cursor: pointer;
    z-index: 1;
  }
  
  .reset .password-toggle:hover {
    color: #000; /* Darker shade on hover */
  }
  
  
  
  
  @media (max-width: 576px) {
    .reset .reset-card {
      padding: 20px;
    }
    .reset .btn-primary {
      font-size: 0.9rem;
    }
    .reset .form-control {
      padding: 10px;
    }
  }