.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .page-button,
  .page-number {
    margin: 0 5px;
    padding: 8px 24px;
    border: 1px solid rgb(229, 231, 235);
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    color: black;
  }
  
  .page-number.active {
    background: linear-gradient(45deg, #007bff, #0056b3);
    color: #fff;
    font-weight: bold;
  }
  
  .page-button:disabled {
    background-color: rgb(244, 248, 251);
    cursor: not-allowed;
    border: 1px solid rgb(229, 231, 235);
    color: gray;
  }
  
  .page-button:hover:not(:disabled),
  .page-number:hover {
    background: linear-gradient(45deg, #007bff, #0056b3);
    color: #fff;
  }

  .page-info {
    font-family: "Inter", serif;
    font-weight: 500;
    font-style: normal;
  }
  

  @media (max-width: 768px) {
    .page-button,
    .page-number {
      margin: 0 2px;
      padding: 4px 10px;
      border: 1px solid rgb(229, 231, 235);
      border-radius: 10px;
      background-color: white;
      cursor: pointer;
      transition: background-color 0.3s;
      font-size: 14px;
    }

    .page-info {
      font-family: "Inter", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
    }
  }