.application-view {
  margin-top: 100px;
}

.application-view .form-section {
  background-color: #fff;
  padding: 20px 20px; /* Top/Bottom: 10px, Left/Right: 20px */
  color: black;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.application-view .section-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.application-view .form-label {
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}

.application-view .form-group {
  margin-bottom: 1rem;
  width: 100%; /* Adjust width to align two fields in a row */
  margin-left: 0; /* Align to the left */
  padding: 0 0px; /* Add horizontal padding */
  display: inline-block; /* Ensure two fields fit side by side */
  vertical-align: top;
}

.application-view .form-group.short-width {
  width: 100%; /* Reduced width for specific fields */
  max-width: 100px; /* Limit maximum width */
  margin-left: auto;
  margin-right: auto; /* Center align smaller fields */
}

.application-view .form-control {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px; /* Adjust padding inside the fields */
  font-size: 1rem;
  width: 100%; /* Ensure input fields take the full width of their container */
}

.application-view input[readonly] {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.application-view select.form-control {
  background-color: #fff;
  cursor: pointer;
}

.application-view .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  margin-bottom: 15px;
}
