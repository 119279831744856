.filter-cards-container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    background-color: #f9f9f9;
  }
  
  .filter-card {
    background-color: #ffffff;
    border: 1px solid rgb(226 232 240 );
    border-radius: 6px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Inter", serif;
    font-size: 14px;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease, transform 0.2s ease;
  }
  
  .filter-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
  
  .filter-card span {
    font-weight: 500;
  }
  
  .filter-card button {
    background-color: #eee;
    border: 0px solid red;
    border-radius: 4px;
    color: red;
    font-size: 12px;
    padding: 4px 8px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
  }
  
  .filter-card button:hover {
    background-color: #d9534f;
    color: #fff;
    border-color: #d43f3a;
  }
  