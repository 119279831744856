/* Container Styling */
.app-ticket-container {
  background-color: #fff; /* Light gray background to match update table */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 10px; /* Remove padding since the table handles spacing */
  max-width: 100%;
  overflow: hidden;
  margin: 20px;
}

/* Table Wrapper for Responsiveness */
.app-ticket-table-wrapper {
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile */
}

/* Table Styling */
.app-ticket-table {
  width: 100%;
  min-width: 600px; /* Minimum width to ensure all columns fit */
  border-collapse: collapse;
  background-color: #fff; /* Match container background */
}

/* Table Headers */
.app-ticket-table th {
  padding: 10px 15px;
  text-align: left;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  /* text-align: center; */
  color: #000;
  background-color: #fff; /* Same as container */
}

/* Table Cells */
.app-ticket-table td {
  padding: 10px 15px;
  text-align: left;
  font-size: 1rem;
  color: #333;
  background-color: #f2f2f2; /* Slightly lighter gray for rows */
  border-bottom: 2px solid #dcdcdd; /* Light gray border between rows */
  border-top: 2px solid #dcdcdd; /* Light gray border between rows */
  
}

/* Table Row Styling */
.app-ticket-table-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.app-ticket-table-row:hover {
  background-color: #f0f0f0; /* Slightly darker gray on hover */
}

/* Status Badge Styling */
.app-ticket-status-badge {
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 0.8rem;
  white-space: nowrap;
  display: inline-block;
}

/* Status Colors */
.app-ticket-status-new {
  background-color: #007bff;
}

.app-ticket-status-in-progress {
  background-color: #ffc107;
  color: #212529;
}

.app-ticket-status-resolved {
  background-color: #28a745;
}

.app-ticket-status-cancelled {
  background-color: #dc3545;
}

.app-ticket-status-reopened {
  background-color: #17a2b8;
}

.app-ticket-status-closed {
  background-color: #6c757d;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .app-ticket-table th,
  .app-ticket-table td {
    padding: 8px 12px;
    font-size: 0.8rem;
  }

  .app-ticket-status-badge {
    padding: 4px 8px;
    font-size: 0.75rem;
  }
}

@media (max-width: 768px) {
  .app-ticket-table {
    min-width: 500px; /* Reduced for tablets */
  }

  .app-ticket-table th,
  .app-ticket-table td {
    padding: 6px 10px;
    font-size: 0.75rem;
  }

  .app-ticket-status-badge {
    padding: 3px 6px;
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .app-ticket-table {
    min-width: 400px; /* Further reduced for mobile */
  }

  .app-ticket-table th,
  .app-ticket-table td {
    padding: 5px 8px;
    font-size: 0.7rem;
  }

  .app-ticket-status-badge {
    padding: 2px 5px;
    font-size: 0.65rem;
  }
}